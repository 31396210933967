@import url('https://fonts.googleapis.com/css?family=Proza+Libre');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
$primary: #912786;

/* MAIN COLOR */

$secondary: #912786;

/* SECONDARY COLOR */

$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
.flash {
    display: none;
}

.navbar-default {
    background: $blk;
    border: none;
}

nav {
    z-index: 1000;
    background: $blk;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: 0px;
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 15px;
            display: flex;
            align-items: center;
            color: $primary;
            outline: none !important;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $primary;
                outline: 0 !important;
            }
            &:hover {
                background: transparent;
                border-radius: 5px;
                color: $wht;
                outline: 0 !important;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0 !important;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 767px) {
        margin: 11px 15px 8px 0px;
    }
}

.logo {
    @media (max-width: 767px) {
        width: 100%;
        max-width: 185px;
    }
}

.header {
    background: url('../img/sliderDemo4.jpg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
}

.header-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 350px 0px;
    @media (max-width: 767px) {
        padding: 50px 0px;
    }
}

.header img {
    width: 100%;
    display: block;
    margin: 0px auto;
    max-width: 1000px;
}

.modal-dialog {
    width: 250px;
    text-align: center;
    margin: 7em auto;
    input[type=submit] {
        padding: 15px 25px;
        width: 100%;
        color: $blk !important;
    }
}

.modal-header,
.modal-footer {
    background: #333;
    color: #fff;
}

input#username {
    margin-bottom: 20px;
}

.modal-title {
    color: #fff
}

.modal-dialog .btn-default {
    width: 100%;
    background: #333;
    border: none;
    color: #fff
}

.cta {
    background: $wht;
    padding: 15px 30px;
    margin: 5px;
    color: $primary;
    &:hover {
        background: $primary;
        color: $wht;
        border: 1px solid $wht;
        transition: all 0.7s ease 0s;
    }
}

.text-white {
    color: $wht;
}

.hero-info {
    background: $primary;
    color: $wht;
    padding: 50px 0px;
    p {
        font-size: 1.2em;
        color: $wht;
        padding: 0 20px;
    }
    h2 {
        font-family: 'Pacifico', cursive;
    }
}

.hero-quote {
    padding: 100px 0;
    background: #a02b94;
    @media(max-width: 767px) {
        padding: 40px 0;
    }
    p {
        font-size: 1.5em;
        color: $wht;
        padding: 0 20px;
        @media (max-width: 767px) {
            font-size: 1.2em;
        }
    }
    h3 {
        font-size: 2em;
        font-family: 'Pacifico', cursive;
        line-height: 1.6em;
        font-weight: lighter;
        color: $wht;
        @media(max-width: 767px) {
            font-size: 1.6em;
        }
    }
}

.hero-process {
    padding: 250px 0;
    background: url('../img/img-full-1.jpg') top center/cover;
    background-color: #e2e2e2;
    @media(max-width: 767px) {
        padding: 20px 0;
    }
    h2 {
        font-family: 'Pacifico', cursive;
        font-size: 3em;
        line-height: 2em;
        @media (max-width: 767px) {
            font-size: 1.8em;
            line-height: auto;
        }
    }
    p {
        font-size: 1.6em;
        margin: 100px 0;
        color: $blk;
        @media (max-width: 767px) {
            margin: 20px 0;
            font-size: 1.2em;
        }
    }
}

.hero-contact {
    background-color: #edeff1;
    padding: 0;
    .col-md-5 {
        background-color: $blk;
        padding: 100px 50px;
        color: $wht;
        @media(max-width: 767px) {
            padding: 40px 20px;
        }
        ul {
            @media (max-width: 480px) {
                padding-left: 10px;
            }
        }
        li {
            list-style: none;
            line-height: 36px;
        }
        a {
            text-decoration: none;
            color: #ccc;
            font-weight: 100;
        }
    }
    .col-md-7 {
        padding: 100px 50px;
        @media(max-width: 767px) {
            padding: 40px 20px;
        }
        p {
            font-size: 1.2em;
            line-height: 1.5em;
            color: #999;
        }
    }
}

footer {
    text-align: center;
    padding-top: 25px;
    background: $blk;
    color: #ccc;
    a {
        text-decoration: none;
        color: $wht;
    }
    a:hover {
        color: $wht !important;
    }
}

body.modal-open {
    overflow: auto;
    padding-right: 0 !important;
}

.navbar-default .navbar-nav>li>a:focus {
    outline: none !important;
}